.ant-card-rtl {
  direction: ltr;
  text-align: right;
}
.home_widget{
  cursor: pointer;
  transition: 0.5s,
}
.home_widget:hover{
  box-shadow: 0px 0 30px rgba(154, 154, 154, 0.538);
}