.site-layout-background {
  background: #fff;
}

.ant-tooltip a {
  color: #fff;
}

/* Style for Load More button */
.load-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1890ff;
    text-decoration: underline;
    width: 100%; /* Set the button width to match the container */
  }
