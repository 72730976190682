@import "~antd/dist/antd.less";

// Customize Variables
@primary-color: #6301f2; // primary color for all components
@secondary-color: #f73f1f;
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #1f1d1e; // major text color
@text-color-secondary: #5a5659; // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
// @border-radius-base: 5px;
@card-radius: 0px;

.site-page-header {
  margin-bottom: 20px;
}
