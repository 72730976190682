.ant-tabs-card.ticket_tab .ant-tabs-nav-list {
    column-gap: 20px;
}

.ant-tabs-card.ticket_tab .ant-tabs-tab {
    padding: 20px;
    border-radius: 0;
    border: 0;
}

.tab_title {
    box-shadow: 5px 5px 10px -5px hwb(0deg 66% 34%);
    cursor: pointer;
    border: 1px solid transparent;
}

.tab_title.active {
    border: 1px solid rgb(99, 1, 242);
}

.tab_title .ant-card-body>div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    font-weight: 500;
}

.tab_count {
    background-color: rgb(99, 1, 242);
    color: rgb(255, 255, 255);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}